import * as React from "react";
import { Header, Container, Segment, Icon, Tab, Embed } from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const panes = [
  {menuItem: {key: 'Potential Investors and Donors', content:(<Segment vertical>
    <Header as="h3">
      <Icon name="play" />
      <Header.Content>
        Users and Gamers
      </Header.Content>
    </Header>
    </Segment>)}, render: () => <Tab.Pane as="borderless">
    <Embed icon='right circle arrow'
    placeholder = 'hello'
    defaultActive = {true}
    iframe={{
      allowFullScreen: true,
      style: {
        padding: 2,
      },
    }}
    url={"https://skymake.github.io"}
      />
    <Segment vertical style={{fontSize:15}}>
        <p>
          This little node on the corner of the internet is a furnace, meant
            for feats of creation that would not have been otherwise possible.
            It would be used by those with dreams and ideas that have once
            desperately needed to take form, but have had no way to crystallize
            in this world until now. Regardless of your background or current
            skill set, you are the medium of your ideas and the one who can
            grant them the opportunity to take shape in the world around you.
        </p>
        <p>
          As the resident spirit in this furnace, let my words grant you the
            sight needed to perform the delicate work taken up by a champion
            of ideas. In this small place you will gain the power to work
            in the dark. Here you will learn to grasp the minimal perspectives
            needed to perform feats of creation. Like champions before
            you who've had to achieve so many things with so little resources,
            with non-existent support, they did not throw away their chance
            despite such dismal outlooks.
        </p>
        <p>
          We are in the dark of so many tools and concepts, we are out of
            resources and always out of time. We may even lack trust in
            ourselves, unaware of what is possible with numerous tools
            which came into being from the latest technological feats and the
            shifts in perspectives they have caused at large. These changes at
            the edge of technological advances echo back to us now. Simplicity
            has greater value, being able to quickly reach out and work in the
            dark of many technical details are features desired of modern
            techniques for a creator.
        </p>
        <p>
          The current landscape created in the wake of large corporations in
            order to bring more to the table has created this opportunity
            for you now. As the spirit of this furnace I endow you of humble
            background the chance to rise up and become the champion for your
            ideas. Take into your hands the conceptual frameworks necessary for
            you to spring into action and bring to life something more in this
            little world of ours, champion.
        </p>
        <p>
          As you progress you will find that you are outnumbered by those against
            this method of creation in the dark. Many would ask the impossible
            instead. Impossible in terms of time, in terms of the conditions against
            us as they have already taken the first move. Even if you are cornered,
            show that it is just as impossible for a champion to not find another
            way.
        </p>
        <p>
          Take these experiences as your first steps towards leveraging modern tools
            and data-enabled decision making in your life. Begin to make more
            effective choices so that your spark is not extinguished here, champion.
        </p>
        <p>
          Remember that actions and sight shape the light.
        </p>
      </Segment>
    </Tab.Pane>},
  {menuItem: {key: 'Potential Investors and Donors', content: (<Segment vertical>
    <Header as="h3">
      <Icon name="exchange" />
      <Header.Content>
        Investors and Donors
      </Header.Content>
    </Header>
  </Segment>)}, render: () => <Tab.Pane as="borderless">
  <Segment vertical style={{fontSize:15}}>
        <p>
          There's currently a huge disconnect between the release of advanced tooling geared for
            ease-of-use and untapped workforce with potential to make their way and quickly learn and
            utilize tools grounded in such advanced techniques. These tools are in a constant state of
            release and development by large software companies to fuel their markets. Such services
            have been tempered and perfected to quickly churn out products. They are meant to reduce
            obstacles between the inception of an idea and its launch as an independent product or
            service. This has in turn fueled a shift in perspective at large. More and more tools
            addressing aspects of development and operations life-cycles have prioritized
            maintainability and scalability which in itself demands simplicity and ease-of-use as the
            focus for any tooling.
        </p>
        <p>
          This newly fostered environment grows increasingly welcoming towards potential developers who
            come from next to nothing with respect to technological or educational background. As long
            as an idea is formed, it can be refined and implemented with very little requisites in a
            short amount of time and yet quickly see monetary results. This is key for the portions of
            the workforce who would naturally live in the dark of these technological advances and who
            require by necessity an additional line of revenue in exchange for their sparse time in
            order to seriously consider such feats of creation as viable in supporting their livelihood.
        </p>
        <p>
          What this potential workforce needs to experience in order to break way into the industry is
            a mentor who can guide them from the dark, and show them what feats they are capable of
            first-hand, nuturing their motivations and addressing exactly what is needed to utilize
            this industrial landscape. Keep in mind that a latent workforce like this is one of
            unprecedented viewpoints and naturally unprecedented ideas as well compared to the
            different kinds of personalities who naturally make their way to this industry.

        </p>
        <p>
          The key obstacle when introducing new concepts and a lifestyle drastically different to what
            such workers have been living is indifference. The key power they need to move this
            direction is a shift in perspective, a mastery of perspectives themselves. They must master
            the art of working in the dark, the art of minimality of perspective. This is an incredibly
            desireable trait from workers in this modern landscape of black boxes and encapsulated
            tooling.
        </p>
        <p>
          Here users will immerse themselves in our medium of choice to overcome indifference to this
            type of growth as the developers of this platform themselves have experienced this medium's
            capability to stimulate and induce an endurance within a person towards achieving their
            goals they would not have displayed elsewhere: the medium of a video game. They will
            receive education in perspective and the use of blackbox tooling carefully encoded by
            researchers of mathematics into core gameplay feedback loops, either through actions
            homomorphic or directly isomorphic (by embedding) actual developer processes and actions
            to perform their feats of creation within the game.
        </p>
        <p>
          Skymake isn't a "gameified" educational platform but a genuinely immersive game, carefully
            thought out by developers of the same background as those they want to reach out to,
            mentors who know what they are capable of and how to begin their first steps into the world
            of abstraction. The game's core focuses on creation at all levels, it is programming
            language agnostic and strives to be tooling agnostic. The initiation of shift in
            perspective only requires that users have experienced learning the mulitplication or
            addition tables at some point in their lives, Skymake will nuture the rest with them. 
        </p>
        <p>
          I have full pitch report which likely addresses any uncertain notions mentioned on this page
            for would be investors and donors 
            <a href="https://skymake.io/404/" target="blank" rel="noopener"> in pdf format here</a>, which
            largely lays out the faults of current educational platforms for meeting the needs of our
            untapped audience and how these faults are addressed within Skymake.
        </p>
      </Segment>
        </Tab.Pane>},
]

const AboutPage = () => {
  return (
    <Container style={{minHeight:"700px"}}>
      <Segment vertical>
        <Header as="h2">
          <Icon name="info circle" />
          <Header.Content>
            About
          </Header.Content>
        </Header>
      </Segment>
      <Segment>
        <p>
          For any coffee time chat between interested Donors, Investors, or Users and Gamers,
          I'm on <a href="https://discord.gg/pyrFYVx" target="blank" rel="noopener">discord #skymake</a>
        </p>
        <p>
          If you notice any cracks in this website, we are grateful to receive notices
          <a href="https://github.com/skymake/website/issues" target="blank" rel="noopener"> on this repository</a>
        </p>
        <p>
          There's currently a section for potential
          early users and a section for investors addressing need.
        </p>
      </Segment>
      <Tab menu={{secondary:true, pointing:true}} panes={panes}/>
    </Container>
  );
};

export default withLayout(AboutPage);
