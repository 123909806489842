import { Link } from "gatsby";
import * as React from "react";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import SidebarMenu from "./SidebarMenu/SidebarMenu";
import { Segment, Icon, Container, Sidebar } from "semantic-ui-react";
import "../css/styles.css";
import "../css/responsive.css";
import "../css/semantic.min.css";
import "prismjs/themes/prism-okaidia.css";
import { Provider } from "react-redux";
import { store } from "../store";

export const menuItems = [
  { name: "Home", path: "/", exact: true, icon: "home", inverted: true },
  { name: "About", path: "/about/", exact: true, icon: "info circle" },
  { name: "Blog", path: "/blog/", exact: false, icon: "newspaper" },
  { name: "Labs", path: "/404/", exact: false, icon: "cogs" },
  { name: "Walkthroughs", path: "/walkthrough/", exact: false, icon: "cogs" },
  { name: "Donate", path: "http://donate.skymake.io", exact: true, icon: "paypal" }
];

const footerMenuStyle = {
  color: 'white',
  size: "huge"
};

const footerMenuActiveStyle = {
  color: '#70a5f9',
  size: "huge"
};

export interface LayoutProps {
  location: {
    pathname: string;
  };
  children: any;
}

const Layout = (props: LayoutProps) => {
  const { pathname } = props.location;
  const isHome = pathname === "/";

  return (
    <Provider store={store}>
      <Sidebar.Pushable as={Segment}>

        <SidebarMenu Link={Link} pathname={pathname} items={menuItems} visible={false} />

        <Sidebar.Pusher style={{ minHeight: "100vh" }}>
          {/* Header */}
          {isHome ? null : <HeaderMenu
            Link={Link}
            pathname={pathname}
            items={menuItems}
          />}

          {/* Render children pages */}
          <div style={{ paddingBottom: 60 }}>
            {props.children}
          </div>

          {/* Footer */}
          <Segment inverted vertical style={{ position: "relative", bottom: 0, width: "100%" }}>
            <Container textAlign="center">
             <p><Link to ="/" style={footerMenuStyle} activeStyle={footerMenuActiveStyle}>Home <Icon name="cloud" /></Link> </p>
             <p><Link to ="/about/" style={footerMenuStyle} activeStyle={footerMenuActiveStyle}>About <Icon name="info circle" /> </Link> </p>
             <p><Link to ="/blog/" style={footerMenuStyle} activeStyle={footerMenuActiveStyle}>Blog <Icon name="history" /></Link> </p>
             <p><a href="https://donate.skymake.io" target="_blank" rel="noopener" style={footerMenuStyle}>Donate <Icon name="paypal" /></a> </p>
             <p><Link to ="/404/" style={footerMenuStyle} activeStyle={footerMenuActiveStyle}>Labs <Icon name="cogs" /></Link> </p>
             <p><a href="https://discord.gg/pyrFYVx" target="_blank" rel="noopener" style={footerMenuStyle}>Discord <Icon name="coffee" /></a> </p>
             <p><Link to ="/404/" style={footerMenuStyle} activeStyle={footerMenuActiveStyle}>Repo <Icon name="heart" /></Link> </p>
            </Container>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Provider>
  );
};

export default Layout;

export const withLayout = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  class WithLayout extends React.Component<P & LayoutProps> {
    render() {
      return (
        <Layout location={this.props.location}>
          <WrappedComponent {...this.props} />
        </Layout>
      );
    }
  };
